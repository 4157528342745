export default {
    list: {
        title: "products",
        class: "min-h-screen flex-1 mt-1 bg-gray-100 px-3 items-center",
        name: 'products',
        options: {
            defaultPageSize: 5,
            actions: {
                isActive: true,
                method: true,
                link: true,
                name: "Actions",
                buttons: [
                    {
                        text: "new_product",
                        icon: "add",
                        action:"addPrd",
                        link: '/products/new',
                        color: 'primary'
                    },
                    {
                        text: "add_subtraction_qty",
                        icon: "import_export",
                        action:"addqte",
                        color: 'primary',
                        class:''
                    },
                ],
            }
        },
        columns: [
            {
                type:"checkbox",
                elements: [{
                   items: [{
                       class: "",
                       type: "checkbox",
                   }, ],
               }, ]
            },
            {
                name: "ID",
                elements: [{
                    items: [{
                        class: "ou",
                        name: "id",
                        type: "text",

                    }, ],
                }, ]
            },
            {
                name: "seller",
                class: "",
                elements: [{
                    items: [{
                        class: "",
                        name: "seller",
                        type: "text",
                    }],
                }, ]
            },
            {
                name: "name",
                elements: [{
                    items: [{
                        class: "",
                        name: "name",
                        type: "text",
                    }, ],
                }, ]
            },
            // {
            //     name: "code",
            //     elements: [{
            //         items: [{
            //             class: "",
            //             name: "code",
            //             type: "text",
            //         }, ],
            //     }, ]
            // },
           
            {
                name: "quantity_in_stock",
                class: "",
                elements: [{
                    items: [{
                        class: "",
                        name: "inStock",
                        arrayD: "details",
                        type: "text",
                    }],
                }, ]
            },
            {
                name: "quantity_total",
                class: "",
                elements: [{
                    items: [{
                        class: "",
                        name: "total",
                        arrayD: "details",
                        type: "text",
                    }],
                }, ]
            },
            {
                name: "quantity_defective",
                class: "",
                elements: [{
                    items: [{
                        class: "",
                        name: "defective",
                        arrayD: "details",
                        type: "text",
                    }],
                }, ]
            },
            {
                name: "price",
                class: "",
                elements: [{
                    items: [{
                        class: "",
                        name: "price",
                        arrayD: "details",
                        type: "text",
                    }],
                }, ]
            },
            {
                name: "Up-sell",
                class: "",
                elements: [{
                    items: [{
                        class: "",
                        name: "upsells",
                        arrayD: "details",
                        type: "text",
                    }],
                }, ]
            },
            {
                name: "date_added",
                class: "",
                elements: [{
                    items: [{
                        class: "",
                        date: true,
                        name: "createdAt",
                        type: "text",
                    }],
                }, ]
            },
            {
                name: "Page produit",
                class: "",
                elements: [{
                    items: [{
                        class: "",
                        link: true,
                        name: "verificationLink",
                        type: "text",
                    }],
                }, ]
            },
        ],
        actions: {
            name: "actions",
            width: "10px",
            class: "rounded-full flex justify-center items-center focus:outline-none w-8 h-8 mr-3 mt-1",
            elements: [{
                    icone: "remove_red_eye",
                    color: "danger",
                    role: true,
                    key: 'read',
                    link: '/products/detail',
                    tooltip: "View",
                },
                {
                    icone: "edit",
                    color: "success",
                    role: true,
                    key: 'update',
                    link: '/products/edit',
                    tooltip: "Update",
                },
                {
                    icone: "delete",
                    color: "danger",
                    role: true,
                    key: 'delete',
                    tooltip: "Delete",
                }
            ],
        },
        filters: {
            name: 'products',
            elements: {
                search: {
                    options: {
                        icon: "search",
                        label: "search",
                        class: "",
                        hint: "search_product"
                    },
                    value: null,
                    type: "search",
                    validate: {
                        trim: true,
                        required: false,
                        minlength: 0,
                    }
                },
                champs: [{
                        label: "seller",
                        key: "seller",
                        name: "fullName",
                        value: null,
                        values: [],
                        class: 'py-2 text-xs  capitalize px-2 outline-none block h-full w-full bg-white',
                        type: "select",
                    },
                    {
                        label: "date",
                        key: "date",
                        value: null,
                        placeholder: "Date",
                        type: "date",
                        class: "py-2 text-xs px-2 outline-none block h-full w-full"
                    },
                ],
            },
        },
    },
}